import { render, staticRenderFns } from "./TradeNetwork.vue?vue&type=template&id=0d2525bc&scoped=true&"
import script from "./TradeNetwork.vue?vue&type=script&lang=js&"
export * from "./TradeNetwork.vue?vue&type=script&lang=js&"
import style0 from "vue-d3-network/dist/vue-d3-network.css?vue&type=style&index=0&lang=css&"
import style1 from "./TradeNetwork.vue?vue&type=style&index=1&id=0d2525bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2525bc",
  null
  
)

export default component.exports