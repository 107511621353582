<template>
  <el-card
    v-loading="loading"
    class="d3-wrapper-card"
    :body-style="{ height: '100%' }"
  >
    <el-card class="d3-slider-card">
      <el-slider
        v-model="force"
        input-size="small"
        :min="0"
        :max="3000"
        vertical
        height="100px"
      ></el-slider>
    </el-card>
    <div
      class="d3-diagram"
      @mousedown="
        () => {
          isClick = true;
        }
      "
    >
      <d3-network
        :net-nodes="nodes"
        :net-links="linkWithColor"
        :options="options"
      />
    </div>
  </el-card>
</template>

<script>
import D3Network from 'vue-d3-network';

export default {
  name: 'TradeNetwork',
  components: {
    D3Network,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    nodes: {
      type: Array,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      force: 800,
      offset: {
        x: 0,
        y: 0,
      },
      orange: '#FF8000',
      isClick: false,
    };
  },
  computed: {
    /**
     * @return {Object} D3-Networkのオプション
     */
    options() {
      return {
        force: this.force,
        offset: this.offset,
        nodeSize: 16,
        nodeLabels: true,
        linkLabels: true,
        canvas: false,
      };
    },
    linkWithColor() {
      return this.links.map(x => ({ ...x, _color: this.orange }));
    },
  },
  methods: {
    moveGraph(e) {
      if (!this.isClick) return;
      const newX = this.offset.x + e.movementX;
      const newY = this.offset.y + e.movementY;

      this.offset = { x: newX, y: newY };
    },
    mouseUp() {
      this.isClick = false;
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.moveGraph);
    window.addEventListener('mouseup', this.mouseUp);
  },
  destroyed() {
    window.removeEventListener('mousemove', this.moveGraph);
    window.removeEventListener('mouseup', this.mouseUp);
  },
};
</script>

<style src="vue-d3-network/dist/vue-d3-network.css"></style>

<style lang="scss" scoped>
.d3-wrapper-card {
  height: 100%;
}
.d3-slider-card {
  position: absolute;
  right: 5%;
  z-index: 100;
  background: #ffffff;
}
.d3-diagram {
  background: #ffffff;
  height: 100%;
  cursor: grab;
  transition: 0.3s ease;
  &:active {
    cursor: grabbing;
  }

  & /deep/ circle.node {
    stroke: #ff8000;
    fill: #ffe0b2;
    stroke-width: 3px;
  }

  & /deep/ text {
    fill: #ff8000;
  }
}
</style>
