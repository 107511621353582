import { render, staticRenderFns } from "./CurrencyNetwork.vue?vue&type=template&id=ce59050c&scoped=true&"
import script from "./CurrencyNetwork.vue?vue&type=script&lang=js&"
export * from "./CurrencyNetwork.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce59050c",
  null
  
)

export default component.exports