<template>
  <trade-network
    :loading="loading"
    :nodes="tradeNetwork.nodes"
    :links="tradeNetwork.links"
  />
</template>

<script>
import tradeNetwork from '@/components/TradeNetwork';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ActionTypes as RootActions } from '@/store/actions';
import { ActionTypes as TradeNetworkActions } from '@/store/modules/tradeNetwork/actions';

// 初期値のための30日分のミリ秒
const ONE_MONTH_DATE = 30 * 24 * 60 * 60 * 1000;

export default {
  name: 'CurrencyNetwork',
  components: {
    tradeNetwork,
  },
  computed: {
    ...mapState(['dateRange']),
    ...mapState('tradeNetwork', ['loading', 'tradeNetwork']),
    ...mapGetters(['calcOffset']),
  },
  methods: {
    ...mapActions('tradeNetwork', [TradeNetworkActions.getTradeNetwork]),
    async getReuest() {
      await this.getTradeNetwork(this.calcOffset);
    },
  },
  watch: {
    dateRange() {
      if (this.dateRange.length === 0) {
        return;
      }

      this.getReuest();
    },
  },
  async created() {
    // この処理なんでここにいるんだろ
    if (this.$route.query.no_date === null) {
      const [start, end] = [
        new Date(Date.now() - ONE_MONTH_DATE),
        new Date(),
      ].map(x => x.getTime());
      const { no_date, ...query } = this.$route.query;
      this.$router.replace({
        query: {
          ...query,
          start,
          end,
        },
      });
    }

    await this.getReuest();
  },
};
</script>

<style lang="scss" scoped></style>
